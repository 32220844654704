// export const API_URL = "https://kwapi.appgrowthcompany.com/api/v1/admin"; // development

export const API_URL = "https://api.kwsaudiarabia.com/api/v1/admin";

export const WORDPRESS_URL =
  "https://cms.kwsaudiarabia.com/wp-json/custom/v1/page/english/marketcenter";

// export const WORDPRESS_URL =
// "https://kwadmin.appgrowthcompany.com/wp-json/custom/v1/page/english/marketcenter";

export const END_POINTS = {
  // auth
  login: "/login",
  forgotPassword: "/forgotPassword",
  get_user: "/profile",
  update_profile: "/updateprofile",
  mediaUpload: "uploadFile",
  verifyOtp: "/verifyOtp",
  resendOtp: "/sendOtp",
  resetPassword: "/setPassword",
  changePassword: "/changePassword",

  // dashboard
  dashboard: "/dashboard",
  propertiesCount: "propertiesCount",

  // trainers
  addTrainer: "/addTrainer",
  getAllTrainers: "/getTrainer",
  getTrainerById: "/getTrainer",
  updateTrainer: "/updateTrainer",
  deleteTrainer: "/deleteTrainer",

  // agents
  addAgent: "/addAgent",
  getAllAgent: "/getAgent",
  getAgentById: "/getAgent",
  updateAgent: "/updateAgent",
  deleteAgent: "/deleteAgent",

  // category
  addCategory: "/addCategory",
  getCategory: "/getCategory",
  getCategoryById: "/getCategory",
  editCategory: "/editCategory",
  deleteCategory: "/deleteCategory",

  // subadmin
  subadmin: "/subAdmin",

  //training material
  getTraningMaterial: "getTraningMaterial",
  addTraningMaterial: "addTraningMaterial",
  editTraningMaterial: "editTraningMaterial",
  deleteTraningMaterial: "deleteTraningMaterial",

  //training
  addTraning: "addTraning",
  getTraning: "getTraning",
  editTraning: "editTraning",
  deleteTraning: "deleteTraning",

  // reseteditPass
  resetEditPass: "resetUserPassword",
  addAppSetting: "addAppSetting",
  getAppSetting: "getAppSetting",
};
